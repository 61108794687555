.about-container{
min-height: 100dvh;
background-size: cover;
background: linear-gradient(0deg, rgba(27, 27, 27, 0.40) 0%, rgba(27, 27, 27, 0.40) 100%), url("../assets/about.jpeg") lightgray 0px -231.213px / 100% 150.148% no-repeat;
display: flex;
gap: 4rem;
justify-content: space-evenly;
align-items: center;
box-shadow: inset 0px 35px 60px black;
}


/* RIGHT-SIDE */

.frame {
display: flex;
flex-direction: column;
gap: 8px;
height: 10rem;
align-items: center;
justify-content: flex-start;
flex-shrink: 0;
position: relative;
background: rgba(255, 255, 255, 0.1);
border-radius: 20px;
padding: 20px;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
backdrop-filter: blur(10px);
}

.right-side {
display: flex;
flex-direction: column;
gap: 2.5rem;
align-items: flex-start;
justify-content: flex-start;
width: 20%;
position: relative;
}


.frame-1 {
color: rgba(215, 197, 134, 0.8);
text-align: center;
font-size: 32px;
font-weight: 600;
position: relative;
}

.frame-2 {
color: var(--white, #ffffff);
text-align: center;
font-size: 20px;
font-weight: 600;
position: relative;
}

.frame-3 {
color: rgba(255, 251, 251, 0.5);
text-align: center;
font-size: var(--h1-font-size, 16px);
line-height: var(--h1-line-height, 158.52%);
font-weight: var(--h1-font-weight, 400);
position: relative;
width: 267px;
}





/* LEFT-SIDE */

.left-side {
background: rgba(255, 255, 255, 0.1);
border-radius: 20px;
padding: 20px;
display: flex;
flex-direction: column;
gap: 2rem;
min-width: 30%;
width: 40%;
align-items: flex-start;
justify-content: center;
height: fit-content;
position: relative;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
backdrop-filter: blur(10px);
}

.despre-noi {
color: var(--light-gold, #d7c586);
font-size: clamp(22px, 5vw, 40px);
line-height: 139.52%;
font-weight: 800;
position: relative;
}

.despre-noi-text {
color: var(--gold, #ddae4d);
text-align: left;
font-size: clamp(14px, 5vw, 18px);
line-height: 1.6;
position: relative;
}


.contact-1 {
--btn-padding: 15px 20px;
--btn-hover-bg: rgb(51, 51, 51);
--btn-transition: .3s;
--btn-letter-spacing: .1rem;
--btn-animation-duration: 1.2s;
--btn-shadow-color: rgba(0, 0, 0, 0.137);
--btn-shadow: 0 2px 10px 0 var(--btn-shadow-color);
--hover-btn-color: #FAC921;
--default-btn-color: #fff;
--font-size: clamp(14px, 4vw, 20px);
--font-weight: 600;
--font-family: Menlo, Roboto Mono, monospace;

}

.contact-1 {
box-sizing: border-box;
padding: var(--btn-padding);
display: flex;
align-items: center;
justify-content: center;
color: var(--hover-btn-color);
font: var(--font-weight) var(--font-size) var(--font-family);
background: black;
cursor: pointer;
outline: none;
text-decoration: none;
font-weight: bolder;
position: relative;
border-top: 1px solid transparent;
border-right: 1px solid transparent;
border-left: 1px solid transparent;
border-bottom: 2px solid var(--hover-btn-color);
transition: var(--btn-transition);
overflow: hidden;
width: fit-content;
box-shadow: var(--btn-shadow);
}

.contact-1 span {
letter-spacing: var(--btn-letter-spacing);
transition: var(--btn-transition);
box-sizing: border-box;
position: relative;
background: inherit;
}

.contact-1 span::before {
box-sizing: border-box;
position: absolute;
content: "";
background: inherit;
}

.contact-1:hover span,
.contact-1:focus span {
color: var(--hover-btn-color);
}

.contact-1:hover span::before,
.contact-1:focus span::before {
animation: chitchat linear both var(--btn-animation-duration);
}

@keyframes chitchat {
0% {
content: "#";
}

5% {
content: ".";
}

10% {
content: "^{";
}

15% {
content: "-!";
}

20% {
content: "#$_";
}

25% {
content: "№:0";
}

30% {
content: "#{+.";
}

35% {
content: "@}-?";
}

40% {
content: "?{4@%";
}

45% {
content: "=.,^!";
}

50% {
content: "?2@%";
}

55% {
content: "\;1}]";
}

60% {
content: "?{%:%";
right: 0;
}

65% {
content: "|{f[4";
right: 0;
}

70% {
content: "{4%0%";
right: 0;
}

75% {
content: "'1_0<";
right: 0;
}

80% {
content: "{0%";
right: 0;
}

85% {
content: "]>'";
right: 0;
}

90% {
content: "4";
right: 0;
}

95% {
content: "2";
right: 0;
}

100% {
content: "";
right: 0;
}
}


@media screen and (max-width: 1250px) {

.about-container{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 2rem;
height: 150dvh;
}

.left-side{
width: 80%;
}


.right-side{
gap: 2rem;
}

.frame
{
display: flex;
justify-content: center;
align-items: center;
background: rgba(255, 255, 255, 0.1);
backdrop-filter: blur(50px);
padding: 1%;
border-radius: 20px;
height: 35%;
width: 100%;
}
}

@media screen and (max-width: 950px) {

.about-container{
/* height: 190dvh; */
align-items: center;
justify-content: center;
}

.left-side{
position: relative;
top: -5rem;
}


.right-side {
padding-top: 2rem;
width: 50%;
flex-direction: column;
display: flex;
gap: 5rem;
align-items: center;
justify-content: center;
}




}

@media screen and (max-width: 768px) {
.left-side {
width: 70%;
}

.right-side{
gap: 1rem;
}
}

@media screen and (max-width: 480px) {
.about-container{
height: 170dvh;
}

.left-side {
width: 85%;
top: -2rem;
}

.right-side {
gap: 0.5rem;
width: 78dvw;
height: 35%;
padding-bottom: 2rem;
}
}