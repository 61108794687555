.contact-container{
display: grid;
grid-template-columns: repeat(2, 1fr);
min-height: 100dvh;
background-color: black;
box-shadow: inset 0px 15px 50px black;
justify-content: center;
}


.left-side-contact{
max-width: 100%;
background-image: url("../assets/contact.png");
background-repeat: no-repeat;
background-size: cover;
z-index: 18;
}

.icon-social-div {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
height: 5rem;
text-align: left;
width: 100%;
}


.icon-social-div a,
.phone-div a,
.mail-div a {
height: 100%;
gap: 3rem;
font-size: clamp(16px, 5vw, 22px);
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
color: inherit;
}


.icon-social-div a img,
.phone-div a img,
.mail-div a img {
max-width: 3rem;
max-height: 3rem;
height: auto;
width: auto;
}


.right-side-contact {
flex-direction: column;
gap: 5%;
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
max-width: 100%;
position: relative;
}

.contacteaza-ne {
font-weight: bolder;
color: var(--primary);
text-align: center;
font-size: clamp(35px, 6vw, 70px);
font-weight: 700;
position: relative;
width: fit-content;
height: fit-content;
}

.icon-social-div,
.phone-div,
.mail-div {
display: flex;
justify-content: flex-start;
align-items: center;
gap: 3rem;
width: 100%;
}



.infos-contact {
display: flex;
flex-direction: column;
align-items: center;
gap: 3rem;
padding-top: 5rem;
color: var(--secondary);
/* Gap between lines */
}

@media screen and (max-width: 1100px) {

.contact-container {
height: 100vh;
display: flex;
flex-direction: column;
background-image: url("../assets/contact.png");
background-repeat: no-repeat;
background-size: cover;
justify-content: center;
align-items: center;
}

.left-side-contact {
display: none;
}

.contacteaza-ne {
background-color: transparent;
}

.social-contact {
flex-direction: column;
width: 100%;
justify-content: flex-start;
align-items: center;
}

.right-side-contact {
background-color: rgba(0, 0, 0, 0.479);
backdrop-filter: blur(50px);
border-radius: 20px;
width: 80%;
display: flex;
justify-content: center;
align-items: center;
padding: 2% 0;
}
}

@media (max-width: 375px){
.mail-div,
.phone-div,
.icon-social-div {
gap: 1rem;
height: 3rem;
}

.mail-div a img,
.phone-div a img,
.icon-social-div a img {
max-height: 2rem;
}

.contacteaza-ne {
font-size: 26px;
}

.infos-contact {
gap: 2rem;
}
}