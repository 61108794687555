
.project-detail-container {
    width: 50%;
    height: 80%;
    position: absolute;
    /* or 'fixed' if you want it to stay in place even when scrolling */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 2%;
    border-radius: 20px;
    border: 2px solid transparent;
    z-index: 1000 !important;
    background: linear-gradient(#2121218c, #000000) padding-box,
        linear-gradient(145deg, transparent 35%, var(--primary)) border-box;

    /* Centering styles */
    top: calc(50% + 3rem);
    left: 50%;
    transform: translate(-50%, -50%);
}
.image-details.active {
    display: block; 
}

.title-project-detail{
    font-size: clamp(22px, 5vw, 40px);
    color: var(--primary);
    text-transform: uppercase;
}

/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 130;
    height: 100%;
    color: white;
    list-style: none;
    background-color: rgba(0, 0, 0, 0.699);
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Close button inside the modal */
.close-button {
    position: absolute;
    top: 3%;
    right: 0%;
    max-height: 5rem !important;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 1500;
   
}

.close-button  img {
     max-height: 5rem;
 }

/* Style for modal overlay when hovering over the close button */
.modal-content button:hover {
    transform: scale(1.3);
}

.other-info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2rem;
    font-size: clamp(12px, 4vw, 18px);
}

.other-info span {
    color: var(--secondary);
}

.important-info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rooms, .bath, .surface{
    display: flex;
    flex-direction: row;
    gap: 5%;
    padding-top: 2%;
    justify-content: center;
    align-items: center;
    min-width: 25%;

}

.icon-info{
    width: 20%;
}


.slideshow-container {
    position: relative;
    max-width: 90%; 
    z-index: 120;
    overflow: hidden;
}

.image-details {
    display: none;
    position: relative;
    width: 100%;
    min-width: 50%;
    border-radius: 10px;
}

.image-details.active {
    display: block;
    width: 100%;
    height: 100%;
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 10px;
}

.arrow-1,
.arrow-2 {
    cursor: pointer;
    height: 80px;
    position: absolute;
    top: 45%;
    width: 4%;
    z-index: 121;
      
}

.arrow-1 {
    left: 10px;
    transform: rotate(-180deg);
}

.arrow-2 {
    right: 10px;
}

.arrow-top,
.arrow-bottom {
    background-color: var(--secondary);
    border: 1px solid black;
    height: 4px;
    right: 0%;
    position: absolute;
    top: 50%;
    width: 100%;
}

.arrow-top:after,
.arrow-bottom:after {
    background-color: #fff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
}

.arrow-top {
    transform: rotate(45deg);
    transform-origin: bottom right;
}

.arrow-top:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
}

.arrow-bottom {
    transform: rotate(-45deg);
    transform-origin: top right;
}

.arrow-bottom:after {
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
}

.arrow-1:hover .arrow-top:after,
.arrow-2:hover .arrow-top:after
{
    left: 0;
    transition-delay: 0.15s;
}

.arrow-1:hover .arrow-bottom:after,
.arrow-2:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
}

.arrow:active {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
}



@media screen and (max-width: 1250px) {
    .project-detail-container {
            width: 80%;
            padding: 10px;
        }
    .title-project-detail {
        font-size: clamp(18px, 5vw, 36px);
    }

    /* Updated .other-info and .important-info */
    .other-info,
    .important-info {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .important-info{
        width: 80%;
        justify-content: flex-start;
        align-items: center;
    
    
    
    }

    .important-info     h4 {
            margin: 0;
        }
          .icon-info {
              width: 5%;
              height: auto;
          }
    .rooms,
    .bath,
    .surface {
        flex-direction: row;
        gap: 3rem;
        width: 100%;
        height: fit-content;
        justify-content: flex-start;
    }

    .arrow-1,
    .arrow-2 {
        height: 80px;
    }

        .modal-content button:hover {
            transform: none;
        }
}

@media screen and (max-width: 768px) {


    .slideshow-container {
            max-width: 100%;
            min-width: 50%;
            margin-top: 20px;
        }


        .other-info{
            gap: 0;
            flex-direction: column;
        }

        .other-info h5 {
            width: 80%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15%;
            height: 0.5rem;
        }

        .span1-projectDetail
        {
            width: 50%;
        }


    
        .image-details {
            max-width: 100vw;
            min-width: 50vw;
        }

    .close-button {
        max-height: 2rem;
      
    
    }

.close-button   img {
      max-height: 2rem;
  }
    .arrow-1, .arrow-2 {
        top: 25%;
    }
}