.footer-container {
-webkit-box-shadow: 0px -40px 45px -14px rgba(0, 0, 0, 0.75);
-moz-box-shadow: 0px -40px 45px -14px rgba(0, 0, 0, 0.75);
box-shadow: 0px -40px 45px -14px rgba(0, 0, 0, 0.75);
min-height: 50vh;
background-color: black;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 20;
}

.link-slot2{
text-decoration: none;
color: white;
}

.link-slot2:hover {

color: var(--primary);

}
.upper-footer{
display: flex;
flex-direction: row;
gap: 2rem;
justify-content: center;
align-items: center;
}

.slot{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 30%;
height: 100%;
}

.slot-1 p {
color: white;
font-size: clamp(12px, 4vw, 20px);
text-align: justify;
}

.slot-2 h5,
.slot-3 h5
{
color: white;
font-size: clamp(20px, 5vw, 30px);
}



.slot-2-p, .copyrights {
width: fit-content;
font-size: clamp(16px, 5vw, 20px);
color: white;
text-align: center;
}

.logo-footer{
height: fit-content;
}


.social-icons{
display: flex;
flex-direction: column;
gap: 3rem;
justify-content: center;
align-items: center;
padding: 5% 0;
width: fit-content;

a{
font-size: clamp(14px, 4vw, 18px);
}
}

.social-connect{
text-transform: uppercase;
text-decoration: none;
color: white;
font-size: clamp(25px, 5vw, 30px);

}

.social-connect:hover{
color: var(--primary);
}

.lower-footer{
font-size: clamp(20px, 5vw, 30px);
color: rgb(255, 255, 255);
padding-top: 3rem;
text-align: center;
a{
text-decoration: none !important;
color: #fc403c;
font-weight: bold;
}

a,
a:link,
a:visited,
a:hover,
a:active {
color: #fc403c;
}

}

@media screen and (max-width: 1000px) {
.footer-container {
height: 100dvh;
}

.upper-footer{
max-width: 100%;
height: 80dvh;
flex-wrap: wrap;
}

.slot-1 {
width: 90%;
height: 40%;
}

.social-icons{
gap: 5%;
display: flex;
flex-direction: column;
}

.slot-2,
.slot-3 {
width: 40%;
height: 50%;
justify-content: center;
align-items: center;
display: flex;

}
}

@media screen and (max-width: 650px) {

.logo-footer{
max-width: 60%;
}

.footer-container {
height: 100dvh;
}

.upper-footer {
width: 100dvw;
height: 80dvh;
position: relative;
top: 5%;
gap: 0;
margin: 0;
padding: 0;
flex-wrap: wrap;
}

.slot-1 {
width: 90%;
max-height: 30%;

}

.slot-1 p {
font-size: clamp(12px, 4vw, 14px);
hyphens: auto;
width: 90%;
letter-spacing: 1px;
word-wrap: break-word;
}


.social-icons{
height: 40%;
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}


.slot-3{
display: flex;
flex-direction: column;
justify-content: center;
height: 25%;
width: 100vw;
padding-top: 2rem;
}

.slot-2 {
width: 100%;
max-height: 18%;
justify-content: center;
align-items: center;
display: flex;


}

.slot-2 p {
font-size: 14px;
}


.lower-footer{
position: relative;
bottom: 0rem;
}

}

@media screen and (max-width: 375px) {


.upper-footer{
top: 5%;
align-content: center;
gap: 10%;
}
.slot-1{
height: fit-content;
width: 100%;
padding: 0;
margin: 0;



}

.slot-1 p {
width: 90%;
font-size: 12px;
margin-top: 3%;
}

.slot-2 {
height: fit-content;
padding: 0;
margin: 0;


}

.slot-2 h5, .slot-3 h5 {
margin: 0;
}
.slot-2 p {
font-size: 12px;
}

.slot-3 {
justify-content: center;
align-items: center;
height: fit-content;
padding-top: 0;


}

.social-icons{
font-size: 12px;
height: 10%;
margin: 0;


}

.social-icons h5,
a {
font-size: 14px
}

p.copyrights{
font-size: 12px;
height: 10%;
}

.slot-3 {
font-size: 12px;
}

.lower-footer{
margin: 0;
padding: 0;

}
}

@media screen and (max-width: 345px) {


.upper-footer {
top: 0%;
align-content: center;
gap: 10%;
}

.logo-footer{
width: 70%;
}

.slot-1 {
height: fit-content;
width: 100%;
padding: 0;
margin: 0;



}

.slot-1 p {
width: 85%;
font-size: 11px;
margin-top: 3%;
}

.slot-2 {
height: fit-content;
padding: 0;
margin: 0;


}

.slot-2  h5 {
font-size: 14px;
margin: 0;
}

.slot-2 p {
font-size: 11px;
}

.slot-3 {
justify-content: center;
align-items: center;
height: fit-content;
padding-top: 0;


}

.slot-3    h5 {
margin: 0;
}

.social-icons {
font-size: 12px;
height: 10%;
margin: 0;


}

.social-icons  h5,
a {
font-size: 10px
}

p.copyrights {
font-size: 11px;
}

.slot-3 {
font-size: 11px;
}

.lower-footer {
margin: 0;
padding: 0;
position: relative;
bottom: 1.5rem;
font-size: large;
}
}

@media (max-width: 500px)  and (max-height: 650px) {

.slot-2{
display: none;}

}