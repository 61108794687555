.servicii-container{
display: flex;
justify-content: center;
align-items: center;
min-height: 100dvh;
z-index: 10;
}


.cards-services{
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap: 2%;
z-index: 100;
justify-content: center;
max-width: 100dvw;
width: fit-content;
height: 70dvh;
}

.card-small{
display: flex;
flex-direction: column;
padding: 2%;
gap: 10%;
justify-items: center;
width: calc(20% - 4%);
height: 45%;
position: relative;
color: white;
background: linear-gradient(to bottom, transparent, rgb(43, 43, 43));
}

.title-card {
text-align: center;
font-size: clamp(16px, 5vw, 30px);
width: 100%;
max-height: 40%;
font-weight: bold;
margin: 0;
}

.card-small p {
text-align: center;
margin: 0;
font-size: clamp(12px, 3vw, 17px);
width: 100%;
height: 70%;
hyphens: auto;
}

.info-servicii{
height: 80%;
}


.card-big{
display: flex;
flex-direction: column;
padding: 2%;
justify-content: center;
align-items: center;
background-color: rgba(255, 255, 255, 0);
width: calc(40% - 2%);
height: 45%;


}

.card-big h1 {
color: var(--primary);
text-align: center;
font-size: clamp(24px, 5vw, 40px);
}

.icon-card{
width: 25%;
height: 25%;
padding: 2%;
}
@media only screen and (max-width: 1150px) {
.cards-services {
flex-direction: column;
align-items: center;
height: auto;
gap: 2rem;
}

.card-small {
width: 80%;
}

.info-servicii{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.card-small{
flex-direction: row;
justify-content: flex-start;
align-items: center;
gap: 15%;
}

.card-big {
width: 80%;
}
}