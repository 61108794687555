

#root{
    --primary: #c1963d;
    --secondary: #d7c586;
    --black: #020202;
    --dark-grey: #3a3637;
    --font-family: Menlo, Monaco, 'Roboto Mono', 'Lucida Console', 'Courier New', monospace;
    letter-spacing: 1.5px;
}

.section {
    min-height: 100dvh;
    background-color: var(--black);
}


.button-whatsapp{
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    aspect-ratio: 1.1;
    z-index: 10000;
    box-shadow: 0 0 40px 10px black ;
    border-radius: 50%;
    animation: pulse 4s infinite;
    }
    
    /* Keyframes for Pulse Animation */
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
    
        70% {
            transform: scale(1.4);
        }
    
        100% {
            transform: scale(1);
        }
    }

    .button-whatsapp:hover {
        animation: none;
    }

.button-whatsapp img {
    width: 5rem;
    height: 5rem;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background: black;
}

::-webkit-scrollbar-thumb {
    background: #c1963d;
}

::-webkit-scrollbar-thumb:hover {
    background: black;
}

@media screen and (max-width:480px){
.button-whatsapp img {
        width: 3rem;
        height: 3rem;
    }
    .button-whatsapp{
        right: 3rem;
        bottom: 2rem;
    }
}

@media screen and (max-width:395px) {
    

    .button-whatsapp {
        right: 1rem;
        bottom: 2rem;
    }
}

@media screen and (max-width:300px) {
    .button-whatsapp img {
        width: 2rem;
        height: 2rem;
    }

    .button-whatsapp {
        right: 2rem;
        bottom: 2rem;
    }
}
.login-button,
.logout-button 
{
    background-color: black;
    color: white;
    position: absolute;
    top: 10rem;
}