.home-container{
    background-image: url("../assets/hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100dvh;
    display: flex;
        flex-direction: column;
        gap: 2rem;
        z-index: 100;
        justify-content: center;
    box-shadow: inset 0px 10px 80px black;
}


.header-homepage{
    z-index: 100;
    min-width: 40%;
    width: 45%;
    max-width:70%;
    position: relative;
    left: 2rem;
    
}

.header-h1{
    color: var(--primary);
    clip: text;
    -webkit-mask-clip: text;
    mask-clip: text;
    backdrop-filter: blur(20px);
    font-size: clamp(40px, 7vw, 60px);

}

.header-h2{
    color: white;
    font-size: clamp(20px, 4vw, 30px);
}

@media screen and (max-width: 950px) {
    .home-container {
        background-position: 30% center;
    }

    .header-homepage {
        width: 75%;
    }
}

@media screen and (max-width: 767px) {
    .home-container {
        background-position: 40% center;
    }

    .header-homepage {
        width: 75%;
    }
}

@media screen and (max-width: 600px) {
    .home-container {
        background-position: 50% center;
    }

    .header-homepage {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {
    .home-container {
        background-position: 60% center;
    }

    .header-homepage {
        width: 80%;
    }
}

@media screen and (max-width: 360px) {
    .home-container {
        background-position: 70% center;
    }

    .header-homepage {
        width: 80%;
    }

    .header-h1 {
        font-size: xx-large;
    }
}