.container-proiecte-all {
padding: 2% 0;
}

.proiecte-container {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
min-height: 100dvh;
padding: 5% 0;
gap: 8rem;
z-index: 100;
}

.buttons-filter {
padding-top: 10%;
display: flex;
flex-wrap: wrap;
gap: 5rem;
justify-content: center;
align-items: center;
z-index: 100;
}

.range{
color: white;
font-size: clamp(12px, 4vw, 20px);
}

.range-div{
display: flex;
gap: 2rem;
justify-content: center;
align-items: center;
}

/* Ensuring the styles override browser defaults */
input[type="range"] {
-webkit-appearance: none;
appearance: none;
width: 60%;
background: transparent;
}

/* Chrome, Safari, Opera: Slider Track */
input[type="range"]::-webkit-slider-runnable-track {
background: var(--secondary) !important;
height: 8px;
border-radius: 4px;
cursor: pointer;
}

/* Chrome, Safari, Opera: Slider Thumb */
input[type="range"]::-webkit-slider-thumb {
-webkit-appearance: none;
margin-top: -8px !important;
height: 24px;
width: 24px;
background: white !important;
border: none;
border-radius: 50%;
cursor: pointer;
}

/* Firefox: Slider Track */
input[type="range"]::-moz-range-track {
background: var(--secondary) !important;
height: 8px;
border-radius: 4px;
cursor: pointer;
}

/* Firefox: Slider Thumb */
input[type="range"]::-moz-range-thumb {
height: 24px;
width: 24px;
background: white !important;
border: none;
border-radius: 50%;
cursor: pointer;
}

/* Focus outline for accessibility */
input[type="range"]:focus {
outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
background: var(--secondary);
}

input[type="range"]:focus::-moz-range-track {
background: var(--secondary);
}

/* Styles for the dropdown selector */
.buttons-filter select {
font-size: clamp(16px, 4vw, 22px);
padding: 10px 15px;
background-color: var(--btn-default-bg, black);
color: var(--secondary, white);
border: none;
cursor: pointer;
}

.buttons-filter span{
color: white;
padding-right: 2rem;
font-size: clamp(16px, 4vw, 22px);
}

/* On focus and hover styles for the select element */
.buttons-filter select:focus,
.buttons-filter select:hover {
background-color: var(--btn-hover-bg, rgb(0, 0, 0));
}

.frame-12143 {
display: flex;
padding-top: 3rem;
flex-direction: column;
cursor: pointer;
gap: 4px;
align-items: center;
justify-content: flex-start;
flex-shrink: 0;
transition: transform 0.3s ease-in-out;
}

.frame-12143:hover {
transform: scale(1.5);
}

.akar-icons-circle-chevron-down-fill {
flex-shrink: 0;
width: 48px;
height: 48px;
position: relative;
overflow: visible;
}

.toate-proiectele {
color: var(--gold, #ddae4d);
text-align: left;
font-size: 18px;
line-height: 139.52%;
font-weight: 500;
position: relative;
}

/* Responsive adjustments */
@media (max-width: 1150px) {

.container-proiecte-all{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.proiecte-container {
flex-direction: column;
flex-wrap: nowrap;

}

.buttons-filter{
flex-direction: column;
width: 50%;
justify-content: flex-start;
align-items: center;
height: fit-content;
padding-top: 5rem;


}

.frame-12143 {
margin: 10%;
padding: 0;
}
}

@media screen and (max-width: 768px) {

.frame-12143:hover {
transform: none;
}

.buttons-filter{
width: 90%;
}

.range-div{
display: flex;
flex-wrap: wrap;
}
}

@media screen and (max-width: 580px) {
.proiecte-container {
gap: 10rem;
}

.frame-12143 {
margin-top: 45%;
}
}