
.card-container {
position: relative;
border-radius: 10px;
width: 30vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
z-index: 100;
}

.card-container::before {
content: '';
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 30%;
background: linear-gradient(to top, rgb(0, 0, 0), transparent);

}

.image-card {
width: 100%;
height: auto;
aspect-ratio: 1 / 1;
border-radius: 10px 10px 0 0;
object-fit: cover;
}

.name-and-button{
display: flex;
font-size: 20px;
padding: 0 2%;
color: var(--primary);
justify-content: space-between;
align-items: center;
}

.card-info{
background-color: rgba(0, 0, 0, 0.329);
backdrop-filter: blur(50px);
border-radius: 20px;
padding: 1%;
display: flex;
position: absolute;
justify-content: center;
width: 90%;
transform: translateY(100%);
flex-direction: column;
height: 10rem;
}
.info-details {
display: flex;
border: 0.5px solid #FAC921;
border-radius: 10px;
gap: 10%;
justify-content: center;
color: rgb(255, 255, 255);
align-items: center;
text-align: center;
text-align: center;
position: relative;
cursor: default;
font-size: clamp(12px, 3vw, 16px);
height: 5rem;
}

.button-card {
--btn-default-bg: rgb(0, 0, 0);
--btn-padding: 15px 20px;
--btn-hover-bg: rgb(51, 51, 51);
--btn-transition: .3s;
--btn-letter-spacing: .1rem;
--btn-animation-duration: 1.2s;
--btn-shadow-color: rgba(0, 0, 0, 0.137);
--btn-shadow: 0 2px 10px 0 var(--btn-shadow-color);
--hover-btn-color: #FAC921;
--default-btn-color: #fff;
--font-size: clamp(14px, 4vw, 20px);
--font-weight: 600;
--font-family: Menlo, Roboto Mono, monospace;
}

/* button settings 👆 */

.button-card {
box-sizing: border-box;
padding: var(--btn-padding);
display: flex;
align-items: center;
justify-content: center;
color: var(--default-btn-color);
font: var(--font-weight) var(--font-size) var(--font-family);
background: var(--btn-default-bg);
border: none;
cursor: pointer;
transition: var(--btn-transition);
overflow: hidden;
box-shadow: var(--btn-shadow);
}

.button-card span {
letter-spacing: var(--btn-letter-spacing);
transition: var(--btn-transition);
box-sizing: border-box;
position: relative;
background: inherit;
}

.button-card span::before {
box-sizing: border-box;
position: absolute;
content: "";
background: inherit;
}

.button-card:hover,
.button-card:focus {
background: var(--btn-hover-bg);
}

.button-card:hover span,
.button-card:focus span {
color: var(--hover-btn-color);
}

.button-card:hover span::before,
.button-card:focus span::before {
animation: chitchat linear both var(--btn-animation-duration);
}

@keyframes chitchat {
0% {
content: "#";
}

5% {
content: ".";
}

10% {
content: "^{";
}

15% {
content: "-!";
}

20% {
content: "#$_";
}

25% {
content: "№:0";
}

30% {
content: "#{+.";
}

35% {
content: "@}-?";
}

40% {
content: "?{4@%";
}

45% {
content: "=.,^!";
}

50% {
content: "?2@%";
}

55% {
content: "\;1}]";
}

60% {
content: "?{%:%";
right: 0;
}

65% {
content: "|{f[4";
right: 0;
}

70% {
content: "{4%0%";
right: 0;
}

75% {
content: "'1_0<";
right: 0;
}

80% {
content: "{0%";
right: 0;
}

85% {
content: "]>'";
right: 0;
}

90% {
content: "4";
right: 0;
}

95% {
content: "2";
right: 0;
}

100% {
content: "";
right: 0;
}
}



@media (max-width: 1150px) {
.card-container {
border-radius: 20px;
width: 65vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
}



.info-details{
width: 60vw;
justify-content: center;
align-items: center;

}

.name-and-button{
align-items: center;
}

.card-info{
width: 60vw;
justify-content: space-between;
}
.button-card{
height: 50%;
width: 50%;
}
}

@media (max-width: 768px) {
.card-container {
border-radius: 20px;
width: 70vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
}

.info-details {
width: 60vw;
justify-content: center;
align-items: center;
font-size: clamp(12px, 4vw, 15px);

}

.name-and-button {
align-items: center;
}

.card-info {
width: 60vw;
justify-content: space-between;
}

.button-card {
height: 40%;
width: 30%;
font-size: clamp(16px, 4vw, 22px);
}
}

@media (max-width: 540px) {
.card-container {
border-radius: 20px;
width: 80vw;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
}

.info-details {
width: 70vw;
justify-content: center;
align-items: center;
font-size: clamp(9px, 4vw, 12px);
}

.name-and-button {
align-items: center;
font-size: 11px;
}

.button-card span{
font-size: 11px;
}

.card-info {
width: 70vw;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
}

.button-card {
height: 40%;
width: 30%;
font-size: clamp(12px, 4vw, 18px);
}
}

@media screen and (max-width: 450px) {
.card-container{
width: 90%;

}
.info-details{
width: 100%;
}

.card-info{
width: 90%;
}
}

@media screen and (max-width: 325px) {
.info-details{
font-size: 11px;
}
}