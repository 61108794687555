
.button-nav-a {
    text-decoration: none;
    color: var(--primary);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.637);
    font-size: clamp(16px, 4vw, 20px);
    text-transform: uppercase;
    z-index: 20001;
}

.button-nav-a:hover {
    color: white;
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track {
    background: black;
}

body::-webkit-scrollbar-thumb {
    background: var(--primary);
}
.logo-navbar-link {
    display: inline-block;
    width: 20rem;
    padding-left: 2%;
}

.logo-navbar {
    max-width: 100%;
    height: auto;
    position: relative;
    top: 0;
    padding-left: 2%;
}

/* Start of Header Styling*/
.header-navbar {
    position: fixed;
    top: 0;
    padding-top: 0.5%;
    width: 100dvw;
    z-index: 1130;
    height: 5rem;
    backdrop-filter: blur(10px);
}

.header-navbar ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    z-index: 1130;
}

.header-navbar li a {
    display: block;
    z-index: 1130;
    text-decoration: none;
    width: fit-content;
    font-size: clamp(16px, 4vw, 26px);
}

.header-navbar .logo {
    display: block;
    float: left;
    padding: 5px 30px;
}

/* menu */
.header-navbar .menu {
    clear: both;
    max-height: 0px;
    z-index: 1130;
    padding: 0 1.5%;
    transition: max-height .5s ease-out;
}

.header-navbar .menu li button {
    background-color: transparent;
    border: none;
    font-size: 22px;
    margin-right: 10px;
    margin-top: 0;
}

/* menu icon */
.header-navbar .menu-icon {
    cursor: pointer;
    float: right;
    z-index: 1130 !important;
    position: relative;
    right: 2rem;
    top: 2.5rem;
    user-select: none;
}

.header-navbar .menu-icon .navicon {
    background: var(--primary);
    display: block;
    height: 0.2rem;
    position: relative;
    width: 3rem;
    transition: all .2s ease-out;
}

.header-navbar .menu-icon .navicon:before,
.header-navbar .menu-icon .navicon:after {
    background: var(--primary);
    content: '';
    display: block;
    height: 0.2rem;
    position: absolute;
    width: 100%;
    transition: all .2s ease-out;
}

.header-navbar .menu-icon .navicon:before {
    top: -8px;
}

.header-navbar .menu-icon .navicon:after {
    bottom: -8px;
}

/* menu btn */
.header-navbar .menu-btn {
    display: none;
}

.header-navbar .menu-btn:checked~.menu {
    max-height: 60dvh;
}

.header-navbar .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
}

.header-navbar .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header-navbar .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header-navbar .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header-navbar .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}

.header-navbar li {
    font-size: 18px;
    color: #222;
    font-family: inherit;
    cursor: pointer;
    position: relative;
    border: none;
    background: none;
    text-transform: uppercase;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: color;
}

.header-navbar li:focus,
.header-navbar li:hover {
    color: #222;
}

.header-li-hover:focus:after,
.header-li-hover:hover:after {
    width: 100%;
    left: 0%;
}

.header-navbar li:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: white;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
}

.button-1 {
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid #fefefe;
    text-transform: uppercase;
    color: #000000;
    text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
    text-decoration: none;
    font-size: 20px;
    transition: 0.3s;
}

.button-1::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% - -2px);
    background-color: #fac921;
    transition: 0.3s ease-out;
    transform: scaleY(1);
}

.button-1::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    text-shadow: 2px 5px 10px rgba(255, 255, 255, 0.5);
    color: white;
    width: calc(100% + 4px);
    height: calc(100% - 50px);
    background-color: #fac921;
    transition: 0.3s ease-out;
}

.button-1:hover::before {
    transform: translateY(-13px);
    height: 0;
    color: white;
}

/* Change menu background when open */
.menu-btn:checked~.menu {
    background-color: black;
}

.menu-btn:checked~.menu li a,
.menu-btn:checked~.menu li button {
    color: white;
}

.navbar-black {
    background-color: black !important;
    color: white;
}

.menu-btn:checked~.menu li a:hover,
.menu-btn:checked~.menu li button:hover {
    color: #CCCCCC;
}
.button-1:hover::after {
    transform: scaleX(0);
    transition-delay: 0.15s;
    color: white;
}

.button-1:hover {
    border: 2px solid #fefefe;
    color: white;
}

.button-1 span {
    position: relative;
    z-index: 3;
}

@media screen and (min-width: 1270px) {
    .header-navbar li {
        float: left;
    }

    .header-navbar li a {
        padding: 20px 30px;
    }

    .header-navbar .menu {
        clear: none;
        float: right;
        max-height: none;
    }

    .header-navbar .menu-icon {
        display: none;
    }

    .header-navbar .menu li button {
        background-color: transparent;
    }
}

@media screen and (max-width: 1260px) {

    .header-navbar,
    .menu,
    .header-navbar ul {
        backdrop-filter: blur(10px);
    }

    .header-navbar ul {
        backdrop-filter: blur(150px);
        position: relative;
        left: -2vw;
        padding-top: 2rem;
        top: -12%;
        width: 100dvw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

    

    li {
        font-size: 35px;
        font-weight: bolder;
    }
}

@media (max-width: 950px) {
.logo-navbar-link,
    .logo-navbar {
        width: 15rem;
        top: 10px;
    }

    .header-navbar ul {
        backdrop-filter: blur(150px);
        position: relative;
        left: 0vw;
        top: 10%;
    }


}

@media (max-width: 768px) {
    .logo-navbar-link,
        .logo-navbar {
            width: 12rem;
        }

    .header-navbar ul {
        backdrop-filter: blur(150px);
        position: relative;
        left: -2vw;
        top: 30%;
    }

}

@media (max-width: 480px) {
    .logo-navbar-link,
        .logo-navbar {
            width: 10rem;
            top: 15px;
        }

                 .header-navbar .menu-btn:checked~.menu {
                     max-height: 100dvh;
                 }
         .header-navbar ul {
             backdrop-filter: blur(150px);
             position: relative;
             left: -2vw;
             top: 40%;
         }

}

@media (max-width: 375px) {
    .logo-navbar-link,
        .logo-navbar {
            width: 8rem;
        }
    .header-navbar .menu-icon {
        top: 2.2rem;

    }

        .header-navbar ul {
            backdrop-filter: blur(150px);
            position: relative;
            left: -2vw;
            top: 39%;
        }

    .header-navbar ul {
        backdrop-filter: blur(150px);
        position: relative;
        left: -2vw;
    }

}

@media (max-width: 321px) {

    .logo-navbar-link,
    .logo-navbar {
        width: 8rem;
    }

    .header-navbar .menu-icon {
        top: 2.2rem;
    }

    .header-navbar ul {
        backdrop-filter: blur(150px);
        position: relative;
    }

    .header-navbar ul {
        backdrop-filter: blur(150px);
        position: relative;
        left: 0vw;
        top: 2rem;
    }

}